/* eslint-disable import/prefer-default-export */
import {
  LOCAL_BAN_REGISTRATION,
  SET_CURRENT_REGISTRATION,
  SET_CURRENT_USER,
} from "~~/redux/actionList";

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  user,
});

export const setCurrentRegistration = (reg) => ({
  type: SET_CURRENT_REGISTRATION,
  reg,
});

export const localBanRegistration = () => ({
  type: LOCAL_BAN_REGISTRATION,
});
