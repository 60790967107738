import EventSeries from "~~/interfaces/EventSeries";
import WelcomeEvent from "~~/interfaces/WelcomeEvent";

export function getPrimaryReferenceEvent(
  eventSeries: EventSeries
): WelcomeEvent | undefined {
  return eventSeries.events.find(
    (event) => event.id === eventSeries.primaryReferenceEventId
  );
}

export function getReferenceStartTime(
  eventSeries: EventSeries
): string | undefined {
  const primaryReferenceEvent = getPrimaryReferenceEvent(eventSeries);
  return primaryReferenceEvent?.startTime;
}

export function getReferenceEndTime(
  eventSeries: EventSeries
): string | undefined {
  const primaryReferenceEvent = getPrimaryReferenceEvent(eventSeries);
  return primaryReferenceEvent?.endTime;
}
