import dayjs from "dayjs";
import STAGE_STATUS from "~~/constants/stageStatus";
import EventOrSeries from "~~/interfaces/EventOrSeries";
import WelcomeEvent from "~~/interfaces/WelcomeEvent";
import { getReferenceStartTime, getReferenceEndTime } from "./eventSeriesUtils";

/**
 * Returns true if the event has any custom registration fields, or if the
 * role/company standard fields are marked as required.
 */
export function hasAnyCustomRegFields(event: WelcomeEvent) {
  if (!event?.customRegistrationFieldsSpec) {
    return false;
  }

  const { customRegistrationFieldsSpec: spec } = event;
  if (!spec.published) {
    return false;
  }

  if (spec.companyRequired && spec.companyVisible) {
    return true;
  }

  if (spec.roleRequired && spec.roleVisible) {
    return true;
  }

  // Return true if there are any custom fields with isDeleted=false
  return spec.fieldsJsonSchema.find((field) => !field.isDeleted);
}

export function getNumDays(event) {
  const { startTime, endTime, timeZone } = event;
  if (!startTime || !endTime || !timeZone) {
    return 1;
  }

  const adjustedStartTime = dayjs.tz(startTime, timeZone);
  const adjustedEndTime = dayjs.tz(endTime, timeZone);

  // Days = number of 24 hour chunks //
  return Math.floor(adjustedEndTime.diff(adjustedStartTime, "hour") / 24) + 1;
}

export function hasStarted(eventOrStage) {
  if (!eventOrStage || !eventOrStage.startTime) {
    return null;
  }
  return Date.parse(eventOrStage.startTime) - new Date().getTime() <= 0;
}

export function isOver(eventOrStage) {
  if (!eventOrStage || !eventOrStage.endTime) {
    return null;
  }
  return Date.parse(eventOrStage.endTime) - new Date().getTime() <= 0;
}

export function isOverOrOnDemand(eventOrStage, event) {
  if (event.eventType == "on_demand") {
    return hasStarted(eventOrStage);
  } else {
    return isOver(eventOrStage);
  }
}

export function canRegister(event, stage) {
  // See the README about when someone can register for an event
  const now = new Date().getTime();
  const end = Date.parse(event.endTime);
  return stage.status === STAGE_STATUS.OPENED || now < end;
}

// Extracts stage ID from stages show page: /events/{eventId}/stages/{stageId}
export function getStageIdFromStageUrl() {
  const pathnameParts = window.location.pathname.split("/");
  return pathnameParts[pathnameParts.length - 1];
}

export const isOnStage = (asset, stageSnapshot, isInjectionStreaming) => {
  switch (asset.assetType) {
    // type "sound_effect" is skipped here intentionally because we want to allow overlap and sound effects are very short so we don't need a long-lived "is on stage" state
    case "audio":
    case "video":
      return (
        stageSnapshot.injectStreamEaId === asset.id && isInjectionStreaming
      );
    case "overlay":
      return stageSnapshot.overlayAssetId === asset.id;
    case "background":
      return stageSnapshot.backgroundAssetId === asset.id;
    case "logo":
      return stageSnapshot.logoAssetId === asset.id;
    default:
      return false;
  }
};

export function getStartAndEndTimes(event: WelcomeEvent, timeZone = "") {
  if (!event) {
    return [undefined, undefined];
  }

  const tz = timeZone || event.timeZone;

  const s = dayjs(event.startTime).format("YYYY-MM-DDTHH:mm");
  const e = dayjs(event.endTime).format("YYYY-MM-DDTHH:mm");

  const start = new Date(s).toLocaleString("en-US", {
    timeZone: tz,
  });

  const end = new Date(e).toLocaleString("en-US", {
    timeZone: tz,
  });

  return [dayjs(start), dayjs(end)];
}

export function eventDurationInDays(event) {
  const [start, end] = getStartAndEndTimes(event);

  const startDay = start.format("YYYY-MM-DD");
  const endDay = end.format("YYYY-MM-DD");

  const diffInDays = dayjs(endDay).diff(dayjs(startDay), "day");

  // diffInDays == 0 if it's only 1 day event, so we add 1 to the diff
  return 1 + diffInDays;
}

export function eventDurationInHours(event) {
  const [start, end] = getStartAndEndTimes(event);

  return dayjs(end).diff(dayjs(start), "hour");
}

export function getEventLandingPageUrl(
  event: WelcomeEvent,
  onboardingState?: string
) {
  let path;
  if (event.stages.length > 1) {
    path = `/events/${event.hashid}`;
  } else {
    path = `/events/${event.hashid}/stages/${event.stages[0].hashid}`;
  }

  if (onboardingState) {
    path += `?onboardingState=${onboardingState}`;
  }

  return path;
}

/**
 * Returns a list of sorted in display order. For past events this is descending
 * (most recent first), for upcoming events this is ascending (soonest first).
 *
 * @param eventsOrSeries A list of WelcomeEvent or EventSeries objects
 * @returns {[EventOrSeries[], EventOrSeries[]]} A tuple of two lists, the first
 * containing past events/series and the second containing upcoming events/series
 */
export function sortedPastAndUpcomingLists(
  eventsOrSeries: EventOrSeries[]
): [EventOrSeries[], EventOrSeries[]] {
  const sortByStartTime = (a, b) => {
    const aTime =
      a.type === "event" ? a.event.startTime : getReferenceStartTime(a.series);
    const bTime =
      b.type === "event" ? b.event.startTime : getReferenceStartTime(b.series);

    // If this is a series with no events, there will be no reference
    // time. These go at the very beginning of the list
    if (!aTime) {
      return -1;
    }
    if (!bTime) {
      return 1;
    }

    return new Date(aTime).getTime() - new Date(bTime).getTime();
  };

  // Split into past and upcoming based on END TIME
  const now = new Date();
  const [pastEvents, upcomingEvents] = eventsOrSeries.reduce(
    ([past, upcoming], seriesOrEvent) => {
      const endTime =
        seriesOrEvent.type === "event"
          ? seriesOrEvent.event.endTime
          : getReferenceEndTime(seriesOrEvent.series);
      if (!endTime) {
        return [past, [...upcoming, seriesOrEvent]];
      }

      if (new Date(endTime).getTime() < now.getTime()) {
        return [[...past, seriesOrEvent], upcoming];
      } else {
        return [past, [...upcoming, seriesOrEvent]];
      }
    },
    [[], []]
  );

  return [
    pastEvents.sort(sortByStartTime).reverse(),
    upcomingEvents.sort(sortByStartTime),
  ];
}
