import * as React from "react";
import { twMerge } from "tailwind-merge";

interface SharedProps {
  variant?: "contained" | "default" | "outlined";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  disabled?: boolean;
}

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    SharedProps {
  component?: "button";
}

interface AnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    SharedProps {
  component: "a";
}

type Props = ButtonProps | AnchorProps;

const styles = {
  base: "inline-flex hover:opacity-90 items-center justify-center py-2 rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 uppercase",
  disabled: "opacity-50 pointer-events-none",
  components: {
    button: {
      default: "text-inherit",
      contained: "bg-purp-500 text-white hover:opacity-90",
      outlined: "bg-transparent border border-white/20 text-inherit",
    },
    a: {
      default: "text-inherit",
      contained: "text-inherit",
      outlined: "text-inherit",
    },
  },
  sizes: {
    default: "px-4",
    small: "px-3",
    medium: "px-6",
    large: "px-6",
  },
  fullWidth: "w-full",
};

export const Button = React.forwardRef<any, Props>(
  (
    {
      className,
      variant = "default",
      size = "default",
      startIcon,
      endIcon,
      component,
      disabled = false,
      ...restProps
    },
    ref
  ) => {
    let Component;
    {
      if (component) {
        Component = component;
      } else if (restProps.href) {
        Component = "a";
      } else {
        Component = "button";
      }
    }

    const props: any = restProps;

    if (Component === "button") {
      props["type"] = props["type"] ?? "button";
    }

    return (
      <Component
        className={twMerge(
          styles.base,
          styles.components[component || "button"][variant],
          styles.sizes[size],
          props.fullWidth && styles.fullWidth,
          disabled && styles.disabled,
          className
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {startIcon && <span className="mr-2">{startIcon}</span>}
        {props.children}
        {endIcon && <span className="ml-2">{endIcon}</span>}
      </Component>
    );
  }
);
