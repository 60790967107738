import store from "~~/redux/store";

import { STAGES_SHOW_VIEW_MODE } from "~~/constants";
import * as uiActions from "~~/redux/actions/ui";

// eslint-disable-next-line import/prefer-default-export
export async function setViewMode(viewMode) {
  return store.dispatch(uiActions.setViewMode(viewMode));
}

// eslint-disable-next-line import/prefer-default-export
export function isProducerViewMode() {
  return store.getState().UI.viewMode === STAGES_SHOW_VIEW_MODE.PRODUCER;
}

// eslint-disable-next-line import/prefer-default-export
export function isAttendeeViewMode() {
  return store.getState().UI.viewMode === STAGES_SHOW_VIEW_MODE.ATTENDEE;
}
