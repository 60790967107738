import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend"; // fallback http load
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import { initReactI18next } from "react-i18next";

import store from "~~/redux/store";

// eslint-disable-next-line import/no-unresolved
import en from "~~/locales/en.json";

// For a reference of what the data format looks like go to https://adair.dev:5000/locale

// To see if it's even active. Useful for components in both Producer and Attendee views.
let isInitialized = false;

// TODO: Constant for gating translation to only attendees. To be removed when feature flag is
//  turned off, or updated when staff are included.
export function isI18nTranslateEnabledForAttendees() {
  const {
    registration: { registrationType },
  } = store.getState().User;

  return (
    isInitialized &&
    (registrationType === "attendee" ||
      registrationType === "speaker" ||
      registrationType === "moderator" ||
      registrationType === null)
  );
}

export function getTranslation(
  namespace,
  translationKey,
  fallback,
  translationOptions
) {
  if (isI18nTranslateEnabledForAttendees()) {
    if (translationOptions) {
      return i18n.t(`${namespace}:${translationKey}`, translationOptions);
    }
    return i18n.t(`${namespace}:${translationKey}`);
  }
  return fallback;
}
// FIXME: We are reaching into the i18next data structures to rewrite the hierarchy to match our expected usage.
// There's an extra en.translation component, which might be because i18next expects to load namespace at a time.
// We are loading all the translation strings at once instead.
// Note: this must be called when initializing and any time language changes.
// Example before hack: { data: { en: { translation: { en: { namespace1: ..., namespace2: ... }}}}}
// Example after hack: { data: { en: { namespace1: ..., namespace2: ... }}}

function mapI18nResourcesToTranslations(locale) {
  if (
    locale !== "en" &&
    i18n.services.resourceStore.data?.[locale]?.translation?.[locale]
  ) {
    i18n.services.resourceStore.data[locale] =
      i18n.services.resourceStore.data[locale].translation[locale];
  }

  if (i18n.language !== locale) {
    i18n.changeLanguage(locale);
  }
}

export function i18nAdairSetLanguage(locale) {
  i18n.reloadResources([locale]).then(() => {
    // FIXME: See comment above for this hack
    mapI18nResourcesToTranslations(locale);
  });
}

export function i18nAdairInitialize(locale) {
  // for all options read: https://www.i18next.com/overview/configuration-options
  const i18nextOptions = {
    resources: en,
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          // local storage options, see: https://github.com/i18next/i18next-localstorage-backend
          prefix: "i18next_res_",
          // Expires in 7 days in millisecond units (following release cadence)
          expirationTime: 7 * 24 * 60 * 60 * 1000,
        },
        {
          // Http backend options, see https://github.com/i18next/i18next-http-backend
          loadPath: "/locale?locale={{lng}}",
          addPath: "/locale?locale={{lng}}",
        },
      ],
    },
    fallbackLng: "en",
    lng: locale,
    keySeparator: ".",
    // Add the env variable locally to enable descriptive logging for i18n when debugging.
    debug: window.CLIENT_ENV.I18N_DEBUG === "true",
    defaultNS: "translation",
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  };

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nextOptions)
    .then(() => {
      // FIXME: See comment above for this hack
      if (locale !== "en") {
        i18nAdairSetLanguage(locale);
      }
      isInitialized = true;
    });
}

// Not using an object to control ordering
const supportedLocales = [
  ["en", "English"],
  ["es", "Español"],
  ["fr", "Français"],
  ["pt", "Português"],
  ["ja", "日本語"],
];

export function availableLanguages() {
  // Creating a copy in case it gets mutated
  return supportedLocales.map((pair) => [pair[0], pair[1]]);
}

export function isSupportedIso6391(locale) {
  return supportedLocales
    .map((pair) => pair[0])
    .includes(locale.substring(0, 2).toLowerCase());
}

export default i18n;
