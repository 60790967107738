import { SET_CHAT_HIDDEN, SET_VIEW_MODE } from "~~/redux/actionList";

export const setChatHidden = (chatHidden) => ({
  type: SET_CHAT_HIDDEN,
  chatHidden,
});

export const setViewMode = (viewMode) => ({
  type: SET_VIEW_MODE,
  viewMode,
});
