/* eslint-disable react/no-unused-state */
import PropTypes from "prop-types";
import React, { Component, createContext } from "react";

const AppUIStateContext = createContext({});

// using class based component to take advantage of free shallow merging (setAppUIState)
class AppUIStateProvider extends Component {
  // weird attaching functions to component state?
  // better performance: descendants don't re-render unnecessarily when using context
  // https://bit.ly/2TuT1Pd

  constructor(props) {
    super(props);
    this.state = {
      isSideSheetOpen: false,
      userHasEntered: false,
      setAppUIState: (nextState = {}) => {
        this.setState(nextState);
      },
    };
  }

  render() {
    return (
      <AppUIStateContext.Provider value={this.state}>
        {this.props.children}
      </AppUIStateContext.Provider>
    );
  }
}

AppUIStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppUIStateProvider, AppUIStateContext };
