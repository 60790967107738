import {
  ADD_ONLINE_USER,
  REMOVE_ONLINE_USER,
  SET_ONLINE_USERS,
} from "~~/redux/actionList";

/* eslint-disable-next-line */
export const setOnlineUsers = (users) => ({
  type: SET_ONLINE_USERS,
  users,
});

export const addOnlineUser = (uid) => ({
  type: ADD_ONLINE_USER,
  uid,
});

export const removeOnlineUser = (uid) => ({
  type: REMOVE_ONLINE_USER,
  uid,
});
