import store from "~~/redux/store";

import { addRegistrations } from "~~/redux/actions/registrations";
import * as userActions from "~~/redux/actions/user";
import axios from "~~/utils/authenticatedAxios";

// eslint-disable-next-line import/prefer-default-export
export async function logoutAndReload() {
  await axios.delete("/users/sign_out");
  // Reload without query string
  // For multitrack, sign out to event url
  // Otherwise, sign out to existing stage url
  // If no event, sign out to root (should only be on event dashboard)
  const { eventUrl } = store.getState().Event;
  if (typeof eventUrl === "undefined") {
    window.location.replace("/");
  } else {
    window.location.replace(eventUrl);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function setCurrentUser(user, registration) {
  if (user) {
    store.dispatch(userActions.setCurrentUser(user));
  }

  if (registration) {
    store.dispatch(userActions.setCurrentRegistration(registration));
    store.dispatch(addRegistrations({ [registration.id]: registration }));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function setCurrentRegistration(registration) {
  return Promise.all([
    store.dispatch(userActions.setCurrentRegistration(registration)),
    store.dispatch(addRegistrations({ [registration.id]: registration })),
  ]);
}
