import {
  ADD_ONLINE_MOBILE_USER,
  REMOVE_ONLINE_MOBILE_USER,
  SET_ONLINE_MOBILE_USERS,
} from "~~/redux/actionList";

/* eslint-disable-next-line */
export const setOnlineMobileUsers = (users) => ({
  type: SET_ONLINE_MOBILE_USERS,
  users,
});

export const addOnlineMobileUser = (uid) => ({
  type: ADD_ONLINE_MOBILE_USER,
  uid,
});

export const removeOnlineMobileUser = (uid) => ({
  type: REMOVE_ONLINE_MOBILE_USER,
  uid,
});
