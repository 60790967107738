/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";
import { THEMES } from "~~/constants";
import { softShadows, strongShadows } from "~~/theme/shadows";
import typography from "~~/theme/typography";
// See https://material-ui.com/customization/typography/#self-hosted-fonts
import AzeretMonoMedium from "~~/fonts/AzeretMono-Medium.ttf";
import ProximaNovaBold from "~~/fonts/ProximaNova-Bold.ttf";
import ProximaNovaReg from "~~/fonts/ProximaNova-Reg.ttf";
import ProximaNovaSbold from "~~/fonts/ProximaNova-Sbold.ttf";

function custom(name, ttf, weight) {
  return {
    fontFamily: "ProximaNova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: weight,
    src: `
      local('ProximaNova'),
      local('${name}'),
      url(${ttf}) format('truetype')
    `,
    unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  };
}

function azeretCustom(name, ttf, weight) {
  return {
    fontFamily: "AzeretMono",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: weight,
    src: `
      local('AzeretMono'),
      local('${name}'),
      url(${ttf}) format('truetype')
    `,
    unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  };
}

const fontFace = [
  azeretCustom("AzeretMono-Medium", AzeretMonoMedium, 500),
  custom("ProximaNova-Reg", ProximaNovaReg, 400),
  custom("ProximaNova-Reg", ProximaNovaReg, 500),
  custom("ProximaNova-Sbold", ProximaNovaSbold, 600),
  custom("ProximaNova-Bold", ProximaNovaBold, 700),
];

const overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": fontFace,
    },
  },
  MuiLinearProgress: {
    root: {
      borderRadius: 3,
      overflow: "hidden",
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 32,
    },
  },
  MuiChip: {
    root: {
      backgroundColor: "rgba(0,0,0,0.075)",
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.95)",
      padding: 8,
      fontSize: 13,
    },
  },
  // Datatables overrides
  MuiTableSortLabel: {
    root: {
      marginTop: "4px",
    },
  },
  MUIDataTableSearch: {
    searchIcon: {
      marginTop: "4px",
    },
    clearIcon: {
      marginTop: "-6px",
    },
  },
};

const baseConfig = {
  direction: "ltr",
  typography,
  overrides,
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
      ...overrides,
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        focus: "rgba(0, 0, 0, 0.12)",
      },
      background: {
        default: colors.common.white,
        contrast: "#282C34",
        dark: "#f4f6f8",
        paper: "#EAEAEA",
        accent: "#f0f2f5",
        dialog: "#ffffff",
      },
      primary: {
        main: "#6E74FF",
        light: "#CBCDFF", // NEW PURP / LIGHT
      },
      secondary: {
        main: "#F1C101",
      },
      error: {
        main: "#F0423C",
      },
      success: {
        main: "#54AA77",
        light: colors.green.A400,
      },
      text: {
        primary: colors.common.black,
        secondary: colors.blueGrey[600],
        light: "#383838",
        contrast: "rgba(0, 0, 0, 0.64)",
      },
      v2: {
        contrast: "#18191b", // same as 1A1B1E
        inverse: "#000000",
        accent: "#EBEBEB",
        primary: "#FFFFFF",
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        darkPaper: "#31343B",
        paper: "#3c424b",
        contrast: colors.common.white,
        accent: "#131316",
        dialog: "#18191B",
      },
      primary: {
        light: "#CBCDFF", // NEW PURP / LIGHT
        main: "#6E74FF",
        dark: "#1F385F",
      },
      secondary: {
        main: "#6E74FF",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
        light: "#999999", // 60% opacity on white
        contrast: "rgba(255, 255, 255, 0.64)",
      },
      error: {
        main: "#F0423C",
      },
      success: {
        main: "#54AA77",
        light: colors.green.A400,
      },
      v2: {
        contrast: "#ffffff",
        inverse: "#ffffff",
        accent: "#2A2B2D",
        primary: "#000000",

        // Welcome's primary color, not the org's brand primary color //
        welcomePrimary: "#6E74FF",

        border: "rgba(255, 255, 255, 0.08)",

        text: {
          neutral: "#F0F0F0",
          darkNeutral: "#909095",
        },

        background: {
          default: "#1D2025",
          dark: "#141619", // 32% black on default //
        },
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#2a2d3d",
        dark: "#222431",
        paper: "#2a2d3d",
      },
      primary: {
        main: "#a67dff",
      },
      secondary: {
        main: "#a67dff",
      },
      text: {
        primary: "#f6f5f8",
        secondary: "#9699a4",
      },
    },
    shadows: strongShadows,
  },
];

export function getTheme(
  themeName,
  brandPalette = {
    primary: "#6E74FF",
    secondary: "#F1C101",
  }
) {
  let themeConfig = themeConfigs.find((theme) => theme.name === themeName);

  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  return createMuiTheme({
    ...baseConfig,
    ...themeConfig,
    palette: {
      ...baseConfig.palette,
      ...themeConfig.palette,
      brand: brandPalette,
    },
    ...{ direction: "ltr" },
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  });
}

export function createTheme(settings = {}) {
  let theme = getTheme();

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
