/**
 * A wrapper around AnyCable for managing websockets.
 */
import type {
  ActionCableMixin,
  ActionCableSubscription,
} from "@anycable/core/create-cable";
import CircularProgress from "@material-ui/core/CircularProgress";
import cable from "~~/utils/cable";
import WLog from "~~/wlog";

type InitObj = object & { channel: string };

export default class CustomCable {
  initObj: InitObj;
  callbacks: ActionCableMixin<any>;
  displayErrorMessages: boolean;
  subscription: ActionCableSubscription | undefined;
  unsubscribed = false;

  constructor(
    initObj: InitObj,
    callbacks: ActionCableMixin<any>,
    displayErrorMessages = false
  ) {
    // Remove all undefined/null values from initObj; they cause backend errors
    this.initObj = Object.entries(initObj)
      .filter(([_key, value]) => value != null)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {}) as InitObj;

    this.callbacks = callbacks;
    this.displayErrorMessages = displayErrorMessages;
  }

  async subscribe(): Promise<void> {
    const subscription = await this._subscribeUntilConnected();
    removeErroredChannel(this.initObj.channel);
    this.subscription = subscription;
  }

  async perform(...args) {
    if (!this.subscription) {
      WLog.assertionFailure(
        "actioncable.message",
        `Message ${args[0]} failed to transmit: not subscribed`
      );
      return;
    }

    // this will just return true or false, depending on if it succeeds or not
    try {
      await this.subscription.perform(...args);
    } catch (e) {
      WLog.assertionFailure(
        "actioncable.message",
        `Message ${args[0]} failed to transmit: ${e.message}`
      );
    }
  }

  async unsubscribe() {
    this.unsubscribed = true;
    this.subscription?.unsubscribe();
    removeErroredChannel(this.initObj.channel);
    this.subscription = undefined;
  }

  /**
   * If the initial connection attempt fails, maybe due to a timeout, the
   * `disconnected` lifecycle method will fire but there will be no attempt
   * to reconnect; in fact, it will send an "unsubscribe" request. In this
   * scenario we must manually retry by creating a new subscription.
   *
   * If such an error happens, this function will call the "onSubscribeError"
   * callback and attempt a retry. The function only resolves after a successful
   * subscription attempt.
   */
  async _subscribeUntilConnected(): Promise<ActionCableSubscription> {
    try {
      // For some reason, @anycable/web modifies the initObj passed in, so we
      // copy the object to avoid mutating the original
      let isFirstConnectionAttempt = true;
      const subscription: ActionCableSubscription = await new Promise(
        (resolve, reject) => {
          const sub = cable.subscriptions.create(
            { ...this.initObj },
            {
              ...this.callbacks,
              disconnected: () => {
                // this.unsubscribed set to "true" means intentional disconnect
                if (!this.unsubscribed) {
                  this._handleChannelError();
                  WLog.log(
                    "info",
                    "actioncable.message",
                    `WARN: Cable disconnected for channel ${this.initObj.channel}`
                  );
                } else {
                  WLog.log(
                    "info",
                    "actioncable.message",
                    `Cable disconnected for channel ${this.initObj.channel}`
                  );
                }

                if (isFirstConnectionAttempt) {
                  const error = new Error("Subscription failed to connect");
                  reject(error);
                  isFirstConnectionAttempt = false;

                  // If disconnect is called on the initial attempt, that means the
                  // connection completely failed. Report this to Sentry. A large
                  // volume of these errors indicates a problem with the server.
                  Sentry?.withScope((scope) => {
                    scope.setExtra("channel", this.initObj.channel);
                    scope.setLevel("warning");
                    Sentry.captureException(
                      "Cable subscription failed to connect"
                    );
                  });

                  return;
                }

                this.callbacks.disconnected?.();
              },
              rejected: () => {
                WLog.log(
                  "warn",
                  "actioncable.message",
                  `WARN: Cable rejected for channel ${this.initObj.channel}`
                );

                if (isFirstConnectionAttempt) {
                  const error = new Error("Subscription rejected");
                  reject(error);
                  this._handleChannelError();
                  isFirstConnectionAttempt = false;
                  return;
                }

                this.callbacks.rejected?.();
              },
              connected: () => {
                WLog.log(
                  "info",
                  "actioncable.message",
                  `Cable connected for channel ${this.initObj.channel}`
                );

                if (isFirstConnectionAttempt) {
                  resolve(sub);
                  isFirstConnectionAttempt = false;
                }

                removeErroredChannel(this.initObj.channel);

                /**
                 * Make sure this.subscription is set before calling the
                 * callback. Otherwise, if we call the connected() callback
                 * before this.subscription is set, and then the consumer calls
                 * perform(), the request will not go through.
                 */
                setTimeout(() => this.callbacks.connected?.());
              },
            }
          );
        }
      );

      this.subscription = subscription;
      await subscription.channel.ensureSubscribed();
      return subscription;
    } catch (error) {
      this._handleChannelError();
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this._subscribeUntilConnected().then(resolve).catch(reject);
        }, 3000);
      });
    }
  }

  async _handleChannelError() {
    if (this.displayErrorMessages) {
      addErroredChannel(this.initObj.channel);
    }
  }
}

/**
 * isRecorder flag. If this is the recorder, we don't want to show the
 * "reconnecting" error message.
 */
let isRecorder = false;
export const setRecorder = () => {
  isRecorder = true;
};

/**
 * Error messaging functions
 */
let errorMessage;

const alerts = {
  RECONNECTING: {
    type: "error",
    id: "reconnecting", // use same ID each time so there's no fade between messages
    text: "You have an unstable connection and are currently disconnected from stage controls.",
    subtext: "Attempting to reconnect",
    icon: CircularProgress,
    duration: null,
    hideCloseIcon: true,
  },
};

const erroredChannels = new Set();
let displayErrorTimeout = null;
const displayErrorTimeoutDuration = 10_000;

function addErroredChannel(channelName) {
  erroredChannels.add(channelName);

  if (erroredChannels.size === 1 && !isRecorder) {
    displayErrorTimeout = setTimeout(() => {
      window.flash_messages.addMessage(alerts.RECONNECTING);
      errorMessage = alerts.RECONNECTING;
    }, displayErrorTimeoutDuration);
  }
}

function removeErroredChannel(channelName) {
  erroredChannels.delete(channelName);

  if (erroredChannels.size === 0 && !isRecorder) {
    clearTimeout(displayErrorTimeout);
    window.flash_messages.removeMessage(errorMessage);
    errorMessage = null;
  }
}
