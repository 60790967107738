import startCase from "lodash/startCase";
import * as onlineMobileUserActions from "~~/redux/actions/onlineMobileUsers";
import * as onlineUserActions from "~~/redux/actions/onlineUsers";
import * as regActions from "~~/redux/actions/registrations";
import * as userActions from "~~/redux/actions/user";
import store from "~~/redux/store";
import { toObject } from "~~/utils/arrayUtils";
import axios from "~~/utils/authenticatedAxios";
import { hasAnyCustomRegFields } from "~~/utils/eventUtils";

function stage() {
  return store.getState().Stage;
}

function registrations() {
  return store.getState().Registrations;
}

// Lounges need special handling because of watchers.
export async function fetchRegsForLounge(idsParam) {
  const { eventId } = stage();

  // When a video starts playing, it adds a registration hardcoded with a uid of 666.
  // Filter this out because it won't return anything on the server side
  if (idsParam && idsParam.length === 1 && idsParam[0] === 666) {
    return Promise.resolve();
  }

  // Remove falsy values. Don't get ids for registrations we already have.
  const regs = registrations();
  const ids = idsParam ? idsParam.filter((id) => Boolean(id) && !regs[id]) : [];

  if (ids.length > 0) {
    const { data } = await axios.post(`/lounge_helpers/fetch_regs/${eventId}`, {
      eventRegistrationIds: ids,
    });
    store.dispatch(regActions.addRegistrations(toObject(data, "id")));
  }
  return Promise.resolve();
}

export async function fetchRegs(idsParam, fetchAll = false) {
  const { eventId } = stage();

  // When a video starts playing, it adds a registration hardcoded with a uid of 666.
  // Filter this out because it won't return anything on the server side
  if (idsParam && idsParam.length === 1 && idsParam[0] === 666) {
    return Promise.resolve();
  }

  // Remove falsy values. Don't get ids for registrations we already have.
  const regs = registrations();
  const ids = idsParam ? idsParam.filter((id) => Boolean(id) && !regs[id]) : [];

  if (ids.length > 0 || fetchAll) {
    const { data } = await axios.get(`/events/${eventId}/event_registrations`, {
      params: {
        ids,
        fetch_all: fetchAll,
        include_emails: true,
      },
    });

    store.dispatch(regActions.addRegistrations(toObject(data, "id")));
  }
  return Promise.resolve();
}

export async function fetchAllRegistrations() {
  return fetchRegs(null, /* fetchAll */ true);
}

export async function fetchReg(id) {
  return fetchRegs([id]);
}

// TODO: Use a special endpoint for this eventually
let numAttendeeRegistrations = null;
export async function fetchNumAttendeeRegistrationsForCurrentEvent(
  useCache = true
) {
  if (numAttendeeRegistrations != null && useCache) {
    return numAttendeeRegistrations;
  }

  await fetchAllRegistrations();
  const regs = registrations();
  const count = Object.values(regs).filter((reg) => {
    return reg.registrationType === "attendee";
  }).length;
  numAttendeeRegistrations = count;
  return count;
}

export function getNumAttendeeRegistrationsForCurrentEventIfCached() {
  return numAttendeeRegistrations;
}

export async function fetchOnlineRegIds() {
  const { eventId, id } = store.getState().Stage;
  const { data } = await axios.get(
    `/events/${eventId}/stages/${id}/stage_presence`
  );
  store.dispatch(onlineUserActions.setOnlineUsers(data.ids));
  store.dispatch(onlineMobileUserActions.setOnlineMobileUsers(data.mobile_ids));
  return data.ids;
}

export async function getOnlineStatusForId(otherId) {
  if (!otherId) {
    return false;
  }

  const { eventId, id } = store.getState().Stage;
  const { data } = await axios.get(
    `/events/${eventId}/stages/${id}/stage_presence/${otherId}`
  );
  return data;
}

export function getNumOnlineAttendees() {
  const regs = registrations();
  return Object.keys(store.getState().OnlineUsers).filter((regId) => {
    const reg = regs[regId];
    return reg && reg.registrationType === "attendee";
  }).length;
}

export async function fetchOnlineRegs() {
  const ids = await fetchOnlineRegIds();
  const filteredIds = ids.filter((regId) => registrations()[regId] == null);
  if (filteredIds.length > 0) {
    await fetchRegs(filteredIds);
  }
}

export async function registerUserForEvent(eventId) {
  const { data } = await axios.post(`/events/${eventId}/event_registrations`);
  return store.dispatch(
    store.dispatch(userActions.setCurrentRegistration(data)),
    store.dispatch(regActions.addRegistrations({ [data.id]: data }))
  );
}

export async function resendConfirmationEmail(email) {
  return axios.post(`/users/confirmation/resend`, { email });
}

export async function sendPasswordlessEmail(email, eventId) {
  return axios.post(`/passwordless/resend`, {
    email,
    eventId,
  });
}

export function getRegistrationTypeDisplayConfig(registration, theme) {
  if (!registration) {
    return null;
  }

  const stageData = stage();
  switch (registration.registrationType) {
    case "staff":
      return {
        color: theme.palette.brand.primary,
        title: startCase(stageData.staffDisplayName),
      };
    case "speaker":
      return {
        color: theme.palette.brand.secondary,
        title: startCase(stageData.speakerDisplayName),
      };
    case "moderator":
      return {
        color: theme.palette.brand.secondary,
        title: "Moderator",
      };
    default:
      return null;
  }
}

export function isEventRegistrationAttendee(eventRegistration) {
  return eventRegistration?.registrationType === "attendee";
}

export function isEventRegistrationStaff(eventRegistration) {
  return eventRegistration?.registrationType === "staff";
}

export function isEventRegistrationSpeaker(eventRegistration) {
  return eventRegistration?.registrationType === "speaker";
}

export function isEventRegistrationModerator(eventRegistration) {
  return eventRegistration?.registrationType === "moderator";
}

export function isEventRegistrationRecordingOrStreaming(eventRegistration) {
  return eventRegistration?.registrationType === "service";
}

export async function autoRegister(
  event,
  user,
  utmSource,
  utmMedium,
  utmCampaign,
  utmTerm,
  utmContent
) {
  const params = {
    user,
    sendRegConfirmation: true,
    ...(utmSource && { utmSource }),
    ...(utmMedium && { utmMedium }),
    ...(utmCampaign && { utmCampaign }),
    ...(utmTerm && { utmTerm }),
    ...(utmContent && { utmContent }),
  };

  // We are not allowed to auto-register if there are custom reg fields present
  if (hasAnyCustomRegFields(event)) {
    throw new Error(
      "Cannot auto-register if there are custom reg fields present"
    );
  }

  const { data } = await axios.patch(`/events/${event.id}/users`, params);
  return data;
}
